<template>
  <div>
    <div class="calender-header">
      <el-button icon="el-icon-d-arrow-left" @click="handleChangeLastYear"></el-button>
      <el-button icon="el-icon-arrow-left" @click="handleChangeLastMonth"></el-button>
      <span>{{ activeYear }}年{{ activeMonth < 10 ? 0 : '' }}{{ activeMonth }}月</span>
      <el-button icon="el-icon-arrow-right" @click="handleChangeNextMonth"></el-button>
      <el-button icon="el-icon-d-arrow-right" @click="handleChangeNextYear"></el-button>
    </div>
    <ul class="calender-body">
      <li v-for="(week, index) in weekList" :key="index">{{ week }}</li>
      <li
        v-for="(date, index) in dateList"
        :key="date + index"
        class="date"
        :class="{ forbid: !date.isActiveMonth, active: date.isActive }"
        @click="handleClickDate(date, index)"
      >
        <span>{{ date.day }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Calendar',
  props: {
    value: {
      default: ''
    },
    defaultDate: {
      default: function() {
        return new Date()
      }
    }
  },
  data() {
    return {
      activeYear: '',
      activeMonth: '',
      activeDay: '',
      activeDate: '',
      weekList: ['日', '一', '二', '三', '四', '五', '六'],
      dateList: []
    }
  },
  computed: {
    result() {
      return `${this.activeYear}-${this.activeMonth < 10 ? 0 : ''}${this.activeMonth}-${this.activeDay < 10 ? 0 : ''}${this.activeDay}`
    }
  },
  mounted() {
    const current = this.defaultDate
    this.activeYear = current.getFullYear()
    this.activeMonth = current.getMonth() + 1
    this.activeDay = current.getDate()
    this.activeDate = this.result
    this.getDateList()
  },
  methods: {
    getDateList() {
      this.dateList = []
      // 本月第一天周几
      const weekNum = new Date(`${this.activeYear}-${this.activeMonth}-1`).getDay()
      // 上个月最后一天日期
      const lastMonthLastDay = new Date(this.activeYear, this.activeMonth, 0).getDate()
      if (weekNum > 0) {
        // 第一天不是周日时
        for (let i = 0; i < weekNum; i++) {
          this.dateList.push({
            isActive: false,
            isActiveMonth: false,
            day: lastMonthLastDay - i
          })
        }
      }
      // 本月日期
      const days = this.getDays(this.activeYear, this.activeMonth - 1)
      for (let i = 0; i < days; i++) {
        this.dateList.push({
          isActive: this.activeDate === this.result && this.activeDay - 1 === i,
          isActiveMonth: true,
          day: i + 1
        })
      }
      // 下个月的天数
      const lastDays = 42 - this.dateList.length
      for (let i = 0; i < lastDays; i++) {
        this.dateList.push({
          isActive: false,
          isActiveMonth: false,
          day: i + 1
        })
      }
    },
    getDays(year, month) {
      const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        days[1] = 29
      }
      return days[month]
    },
    handleChangeLastYear() {
      // 上一年
      this.activeYear--
      this.getDateList()
    },
    handleChangeNextYear() {
      // 下一年
      this.activeYear++
      this.getDateList()
    },
    handleChangeLastMonth() {
      // 上一月
      if (this.activeMonth === 1) {
        this.activeYear--
        this.activeMonth = 12
      } else {
        this.activeMonth--
      }
      this.getDateList()
    },
    handleChangeNextMonth() {
      // 下一月
      if (this.activeMonth === 12) {
        this.activeYear++
        this.activeMonth = 1
      } else {
        this.activeMonth++
      }
      this.getDateList()
    },
    handleClickDate(date, index) {
      // 选择日期事件, 暂不支持点击其他月份的日期
      if (!date.isActiveMonth) {
        return
      }
      for (let i = 0; i < this.dateList.length; i++) {
        this.$set(this.dateList[i], 'isActive', i === index)
      }
      this.activeDay = date.day
      this.activeDate = this.result
      this.$emit('change', this.result)
      this.$emit('input', this.result)
    }
  }
}
</script>

<style lang="less" scoped>
.calender-header {
  margin-bottom: 20px;
  text-align: center;
  .el-button {
    width: auto;
    height: 12px;
    line-height: 12px;
    padding: 0;
    margin: 0 10px;
    border: none;
    box-shadow: none;
    color: #d1d1d1;
    ::v-deep i {
      font-size: @small;
    }
  }
  span {
    margin: 0 10px;
    color: #1f1f1f;
    font-size: @small;
    font-weight: bold;
  }
}
.calender-body {
  .flexStyle(flex, flex-start, flex-start);
  flex-wrap: wrap;
  li {
    width: calc(100% / 7);
    margin-bottom: 20px;
    text-align: center;
    vertical-align: middle;
    color: #6d6d6d;
    &.date {
      color: #1f1f1f;
      cursor: pointer;
    }
    &.forbid {
      color: #a5a5a5;
      cursor: not-allowed;
    }
    &.active {
      position: relative;
      color: #ffffff;
      z-index: 222;
      &:after {
        content: '';
        width: 24px;
        height: 25px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        background: #2878ff;
        border-radius: 6px;
      }
      span {
        position: relative;
        z-index: 222;
      }
    }
  }
}
</style>
