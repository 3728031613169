<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>职位管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <div style="display: flex; height: 126px">
        <div class="yt-content has-border" style="margin-right: 10px">
          <p class="title">职位简历</p>
          <div class="statistic-wrapper">
            <div class="icon-background">
              <YTIcon :href="'#icon-jianli'" />
            </div>
            <ul class="has-border" style="flex-basis: 172px">
              <li style="margin-bottom: 5px">
                <span style="font-size: 20px;font-weight: bold">{{ notViewResumeNum }}</span>
                份
              </li>
              <li style="font-size: 12px">未查看的简历</li>
            </ul>
            <div style="width: 80px"></div>
            <ul style="flex-basis: 180px; flex-grow: 1">
              <li style="margin-bottom: 5px">
                <span style="font-size: 20px;font-weight: bold">{{ receivedResumeNum }}</span>
                份
              </li>
              <li style="font-size: 12px">已收到的简历</li>
            </ul>
            <div class="detail-button" @click="$router.push('/manage/recruitment/staff')">
              详情
              <YTIcon style="font-size: 9px; margin-bottom: 1px" :href="'#icon-shuangjiantou'" />
            </div>
          </div>
        </div>
        <div class="yt-content has-border">
          <p class="title">招聘职位</p>
          <div class="statistic-wrapper">
            <div class="icon-background">
              <YTIcon :href="'#icon-qiye-fuben'" />
            </div>
            <ul class="has-border" style="flex-basis: 172px">
              <li style="margin-bottom: 5px">
                <span style="font-size: 20px;font-weight: bold">{{ recruitmentJobNum }}</span>
                个
              </li>
              <li style="font-size: 12px">招聘中的职位</li>
            </ul>
            <div style="width: 80px"></div>
            <ul class="has-border" style="flex-basis: 180px; flex-grow: 1">
              <li style="margin-bottom: 5px">
                <span style="font-size: 20px;font-weight: bold">{{ examUserNum }}</span>
                人
              </li>
              <li style="font-size: 12px">职位测试人数</li>
            </ul>
            <div style="width: 80px"></div>
            <ul style="flex-basis: 180px; flex-grow: 1">
              <li style="margin-bottom: 5px">
                <span style="font-size: 20px;font-weight: bold">{{ offlineJobNum }}</span>
                个
              </li>
              <li style="font-size: 12px">下线职位数</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="yt-header">
        <div class="yt-header-toolbar">
          <el-dropdown trigger="click" placement="bottom-start" @command="selectRecruitType">
            <p>招聘类型：{{ jobRecruitTypeOptions.label[jobRecruitType] }}<i class="el-icon-arrow-down el-icon--right"></i></p>
            <el-dropdown-menu class="recruitment-dropdown" slot="dropdown">
              <el-dropdown-item
                v-for="(item, key) in jobRecruitTypeOptions.list"
                :key="key"
                :command="item.code"
                :class="{ active: item.code === jobRecruitType }"
              >
                {{ item.value }}
                <i class="el-icon-check el-icon--right"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" placement="bottom-start" @command="selectRecruitStatus">
            <p>职位状态：{{ jobRecruitStatusOptions.label[jobRecruitStatus] }}<i class="el-icon-arrow-down el-icon--right"></i></p>
            <el-dropdown-menu class="recruitment-dropdown" slot="dropdown">
              <el-dropdown-item
                v-for="(item, key) in jobRecruitStatusOptions.list"
                :key="key"
                :command="item.code"
                :class="{ active: item.code === jobRecruitStatus }"
              >
                {{ item.value }}
                <i class="el-icon-check el-icon--right"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-date-picker
            class="select-style"
            :picker-options="pickerOptions"
            v-model="activeDate"
            type="daterange"
            range-separator="—"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="selectDate"
          >
          </el-date-picker>
        </div>
        <el-button type="primary" size="small" @click="toRelease">
          <div>
            <i class="el-icon-circle-plus-outline" />
            <p style="margin-right: 10px">发布职位</p>
          </div>
        </el-button>
      </div>
      <div class="yt-content" style="margin-top: 10px;height: calc(100% - 126px - 72px)">
        <el-table ref="staffTable" :data="jobList" v-loading="jobLoading" element-loading-text="加载中" class="yt-table" height="calc(100% - 35px)">
          <el-table-column label="招聘类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="tag-wrapper">
                {{ jobRecruitTypeOptions.label[scope.row.recruitType] }}
                <div v-if="!scope.row.recruitType" class="tag-item department-item">
                  {{ scope.row.year }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="职位名称" prop="jobName" show-overflow-tooltip />
          <el-table-column label="职位类型" show-overflow-tooltip>
            <template slot-scope="scope"> {{ jobTypeEnum[scope.row.jobType] }} </template>
          </el-table-column>
          <el-table-column label="工作性质" show-overflow-tooltip>
            <template slot-scope="scope"> {{ workTypeEnum[scope.row.workType] }} </template>
          </el-table-column>
          <el-table-column label="工作地点" show-overflow-tooltip>
            <template slot-scope="scope"> {{ scope.row.workPlace.toString() }} </template>
          </el-table-column>
          <el-table-column label="发布时间" show-overflow-tooltip>
            <template slot-scope="scope"> {{ $formatTime(scope.row.createTime, 'yyyy-MM-dd') }} </template>
          </el-table-column>
          <el-table-column label="申请人数" prop="applyNum" show-overflow-tooltip />
          <el-table-column label="职位状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="dot" :style="{ backgroundColor: colorEnum[scope.row.status] }"></span>
              <span :style="{ color: colorEnum[scope.row.status] }">{{ jobRecruitStatusOptions.label[scope.row.status] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="190px">
            <template slot-scope="scope">
              <span class="tool-button" @click="checkDetail(scope.row)">查看</span>
              <span class="tool-button" @click="updateDetail(scope.row)" style="margin-right: 10px;">编辑</span>
              <span class="tool-button" @click="deleteDetail(scope.row)" style="margin-right: 10px;">删除</span>
              <span class="tool-button" @click="toPutOn(scope.row)" v-if="scope.row.status === 1">上架</span>
              <span class="tool-button" @click="toPutOff(scope.row)" v-else-if="scope.row.status === 0">下架</span>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!jobLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-elevator
          show-sizer
          show-total
        />
      </div>
    </div>
    <ConfirmDialog ref="deleteJobVisible" :title="'提示'" @on-ok="deleteJobDetail">
      <div style="width: 260px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        该职位当前招聘中，是否确认删除！
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="onlineJobVisible" :title="'提示'" @on-ok="putOn">
      <div style="width: 260px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        是否确认上架该职位！
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="offlineJobVisible" :title="'提示'" @on-ok="putOff">
      <div style="width: 260px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        是否确认下架该职位！
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import jobApi from '@api/job'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import Calendar from '@components/common/version2/Calendar'
export default {
  name: 'JobList',
  components: { YTIcon, ConfirmDialog, Calendar },
  data() {
    return {
      notViewResumeNum: 0, //未查看的简历
      receivedResumeNum: 0, //已收到的简历
      examUserNum: 0, //职位测试人数
      offlineJobNum: 0, //下线职位数
      recruitmentJobNum: 0, //招聘中的职位
      applyMostJobInfo: {
        jobName: '',
        number: 0
      },
      viewMostJobInfo: {
        jobName: '',
        number: 0
      },
      jobRecruitType: '',
      activeRecruitType: '',
      jobRecruitTypeOptions: {
        label: {},
        list: []
      }, //招聘类型
      jobRecruitStatus: '',
      jobRecruitStatusOptions: {
        label: {},
        list: []
      }, //招聘职位状态
      year: '',
      deleteJobId: '', //删除职位id
      colorEnum: ['#448BFF', '#999999'],
      jobList: [],
      jobLoading: false,
      activeDate: [],
      pageNum: 0,
      pageSize: 10,
      total: 0,
      jobTypeEnum: {}, //职位类型
      workTypeEnum: {}, //工作性质
      onlineJobId: '', //上架职位id
      offlineJobId: '', //下架职位id
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 选当前时间之前的时间
        }
      }
    }
  },
  mounted() {
    this.getResumeNum()
    this.getRecruitmentNum()
    this.getMostJob()
    this.getJobList()
    this.getJobType()
    this.getWorkType()
    this.getJobRecruitType()
    this.getJobStatus()
  },
  methods: {
    getResumeNum() {
      //获取头部左侧数据
      jobApi.getResumeNum().then(res => {
        this.notViewResumeNum = res.res.notViewResumeNum
        this.receivedResumeNum = res.res.receivedResumeNum
      })
    },
    getRecruitmentNum() {
      //获取头部右侧数据
      jobApi.getRecruitmentNum().then(res => {
        this.recruitmentJobNum = res.res.recruitmentJobNum
        this.examUserNum = res.res.examUserNum
        this.offlineJobNum = res.res.offlineJobNum
      })
    },
    getMostJob() {
      //获取浏览最多 申请最多数据
      jobApi.getMostJob().then(res => {
        this.applyMostJobInfo = this.$deepCopy(res.res.applyMostJobInfo)
        this.viewMostJobInfo = this.$deepCopy(res.res.viewMostJobInfo)
      })
    },
    getJobList() {
      //获取职位列表
      this.jobLoading = true
      let payload = {
        recruitType: this.jobRecruitType,
        jobStatus: this.jobRecruitStatus
      }
      if (this.activeDate) {
        payload.startTime = this.activeDate[0]
        payload.endTime = this.activeDate[1]
      }
      jobApi
        .getJobList(payload, this.pageNum, this.pageSize)
        .then(res => {
          this.jobList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.jobLoading = false
        })
    },
    selectDate(data) {
      //日期筛选
      this.pageNum = 0
      this.getJobList()
    },
    getJobType() {
      //获取工作性质
      jobApi.getJobType().then(res => {
        this.jobTypeEnum = res.res
      })
    },
    getWorkType() {
      //获取工作性质
      jobApi.getWorkType().then(res => {
        this.workTypeEnum = res.res
      })
    },
    getJobRecruitType() {
      //获取招聘类型
      jobApi.getJobRecruitType().then(res => {
        let map = {}
        res.res.forEach(item => {
          map[item.code] = item.value
        })
        this.jobRecruitTypeOptions = {
          list: res.res,
          label: map
        }
        this.jobRecruitType = res.res[0].code
      })
    },
    getJobStatus() {
      //获取职位状态
      jobApi.getJobStatus().then(res => {
        let map = {}
        res.res.forEach(item => {
          map[item.code] = item.value
        })
        this.jobRecruitStatusOptions = {
          list: res.res,
          label: map
        }
        this.jobRecruitStatus = res.res[0].code
      })
    },
    toRelease() {
      //发布职位
      this.$router.push({ name: 'jobRelease', params: { mode: '1' } })
    },
    checkDetail(row) {
      this.$router.push({
        name: 'jobDetail',
        params: {
          jobId: row.jobId
        }
      })
    },
    updateDetail(row) {
      this.$router.push({
        name: 'jobRelease',
        params: {
          detail: row,
          mode: '0',
          fromRoute: 'jobList'
        }
      })
    },
    deleteDetail(data) {
      //删除职位
      this.$refs.deleteJobVisible.open()
      this.deleteJobId = data.jobId
    },
    deleteJobDetail() {
      jobApi.deleteJobDetail(this.deleteJobId).then(res => {
        if (res.res === true) {
          this.getRecruitmentNum()
          this.$message.success('删除职位成功')
          this.getJobList()
        }
      })
    },
    toPutOn(data) {
      // 打开上架确认框
      this.$refs.onlineJobVisible.open()
      this.onlineJobId = data.jobId
    },
    toPutOff(data) {
      // 打开下架确认框
      this.$refs.offlineJobVisible.open()
      this.offlineJobId = data.jobId
    },
    putOn() {
      // 上架
      jobApi.online(this.onlineJobId).then(res => {
        if (res.res === true) {
          this.$message.success('上架职位成功')
          this.getJobList()
          this.getRecruitmentNum()
        }
      })
    },
    putOff() {
      // 下架
      jobApi.offline(this.offlineJobId).then(res => {
        if (res.res === true) {
          this.$message.success('下架职位成功')
          this.getJobList()
          this.getRecruitmentNum()
        }
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.getJobList()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.getJobList()
    },
    selectRecruitType(data) {
      //招聘类型筛选
      this.jobRecruitType = data
      this.pageNum = 0
      this.getJobList()
    },
    selectRecruitStatus(data) {
      //职位状态筛选
      this.jobRecruitStatus = data
      this.pageNum = 0
      this.getJobList()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/recruitmentDropdownMenu';
@import '~@/theme/tableDropdownMenu';
.yt-main {
  padding-top: 0;
}
.yt-container {
  height: 100%;
  padding: 10px;
  .yt-content {
    position: relative;
    &.has-border {
      &:before {
        content: '';
        width: 4px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 10px;
        background-color: #448bff;
        border-radius: 1px;
      }
    }
    .title {
      line-height: 20px;
      color: #666;
    }
  }
}
.yt-header {
  margin: 10px 0;
  padding: 0 20px;
  height: 52px;
  align-items: center;
  p {
    font-size: @medium;
    margin-right: 40px;
    flex-shrink: 0;
    cursor: pointer;
  }
}
.statistic-wrapper {
  .flexStyle(flex, flex-start);
  margin-top: 20px;
  .icon-background {
    .flexStyle();
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 20px;
    background-color: #e6f0ff;
    border-radius: 6px;
  }
  svg {
    font-size: 33px;
    color: #448bff;
  }
  .has-border {
    position: relative;
    &:after {
      content: '';
      width: 1px;
      height: 40px;
      background-color: #d3d3d3;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
  .detail-button {
    align-self: flex-end;
    flex-shrink: 0;
    margin-bottom: 1px;
    color: #448bff;
    cursor: pointer;
  }
}
.department-item {
  margin-left: 12px;
  background-color: #effff4 !important;
  color: #4ce47a !important;
}
.select-style {
  padding: 0 10px;
  width: 260px;
  height: 32px;
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 5px;
}
</style>
